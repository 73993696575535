import Layout from '@/layout';
var idleResourcesRouter = {
  path: '/idleResources',
  component: Layout,
  redirect: '/idleResources/list',
  name: 'idleResources',
  meta: {
    title: '闲置资源',
    icon: 'box'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/idleResources/list');
    },
    name: 'idleResourcesList',
    meta: {
      title: '闲置资源',
      icon: ''
    }
  }, {
    path: 'edit/:id?',
    name: 'idleResourcesCreat',
    component: function component() {
      return import('@/views/idleResources/edit');
    },
    meta: {
      title: '添加闲置资源',
      noCache: true,
      activeMenu: "/idleResources/index"
    }
  }]
};
export default idleResourcesRouter;