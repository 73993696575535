import Layout from '@/layout';
var activityRouter = {
  path: '/activity',
  component: Layout,
  redirect: '/activity/list',
  name: 'Activity',
  meta: {
    title: '活动管理',
    icon: 'coin'
  },
  children: [{
    path: 'list',
    component: function component() {
      return import('@/views/activity/list');
    },
    name: 'activityList',
    meta: {
      title: '活动列表',
      icon: ''
    }
  }, {
    path: 'edit/:id?',
    name: 'activityCreat',
    component: function component() {
      return import('@/views/activity/edit');
    },
    meta: {
      title: '添加活动',
      noCache: true,
      activeMenu: "/activity/list"
    }
  }, {
    path: 'type',
    name: 'activityType',
    component: function component() {
      return import('@/views/activity/type/list');
    },
    meta: {
      title: '活动分类'
    }
  }]
};
export default activityRouter;