import Layout from '@/layout';
var playCountryRouter = {
  path: '/playCountry',
  component: Layout,
  redirect: '/playCountry/index',
  name: 'PlayCountry',
  meta: {
    title: '乐玩乡村',
    icon: 'place'
  },
  children: [{
    path: 'index',
    component: function component() {
      return import('@/views/playCountry/index');
    },
    name: 'playCountryIndex',
    meta: {
      title: '坐标管理',
      icon: ''
    }
  }, {
    path: 'markersCreat/:id?',
    name: 'markersCreat',
    component: function component() {
      return import('@/views/playCountry/edit');
    },
    meta: {
      title: '添加坐标',
      noCache: true,
      activeMenu: "/playCountry/index"
    }
  }, {
    path: 'article',
    name: 'markerArticle',
    component: function component() {
      return import('@/views/playCountry/article/list');
    },
    meta: {
      title: '详情管理',
      icon: 'clipboard'
    }
  }, {
    path: 'articleCreat/:id?',
    name: 'markerArticleCreat',
    component: function component() {
      return import('@/views/playCountry/article/edit');
    },
    meta: {
      title: '添加详情',
      noCache: true,
      activeMenu: "/playCountry/articleCreat"
    }
  }, {
    path: 'settings',
    name: 'markerSettings',
    component: function component() {
      return import('@/views/playCountry/settings');
    },
    meta: {
      title: '地图设置'
    }
  }, {
    path: 'way',
    name: 'markerWay',
    component: function component() {
      return import('@/views/playCountry/way/list');
    },
    meta: {
      title: '体验路线'
    }
  }, {
    path: 'way/edit',
    name: 'markerWayEdit',
    component: function component() {
      return import('@/views/playCountry/way/edit');
    },
    meta: {
      title: '添加体验路线'
    }
  }]
};
export default playCountryRouter;